import React, { useEffect } from "react";
import { Container } from "reactstrap";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Button } from "antd";

const Layout = ({ children }) => {
  useEffect(() => {
    console.log("vô vô");
  }, []);
  return (
    <div className="d-flex flex-column h-100">
      <NavBar />
      <Container className="flex-grow-1 mt-5">{children}</Container>
      <Footer />
    </div>
  );
};

export default Layout;
