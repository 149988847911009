import React from "react";
import styles from "./policy.module.css";
import { Typography } from "antd";
const { Title, Text } = Typography;
const PrivacyPolicy = () => {
  return (
    <div className={styles.main}>
      <img src={"/logo_xeneawallet.png"} alt="Your logo blue" width={100} />
      <h2 style={{ marginBottom: 0 }}>
        <b>Xenea Wallet - Privacy Policy</b>
      </h2>
      <span
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif',
        }}
      >
        (updated 7/30/2024)
      </span>

      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Introduction</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        The following is the Privacy Policy (the “<b>Privacy Policy</b>”) of
        CROSSTECH DMCC (Company Registration No. DMCC193589) (the “
        <b>Company</b>
        ”, “<b>we</b>”, “<b>us</b>” or “<b>our</b>”), a company incorporated in
        the Dubai Multi-Commodities Centre, United Arab Emirates. The Company
        collects information about you (“<b>User</b>”, “<b>you</b>”, “
        <b>your</b>
        ”) from various sources when you access, download, and/or use the app
        Xenea Wallet&nbsp; (the “<b>Wallet</b>”) and/or access or use to provide
        any of our Services to you, to protect our legitimate interests, to
        analyze and improve our Services, to communicate with you, and to comply
        with our legal and regulatory obligations. This Privacy Policy (“Privacy
        Policy”) covers our treatment of the personally identifiable information
        that we gather when you access or use our app.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Please note that we take the protection of personal data very seriously.{" "}
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        The Privacy Policy applies to the app services provided by the Company
        (the “<b>Services</b>”), but excludes any products, applications or
        services that have separate privacy policies which do not incorporate
        this Privacy Policy. For the purposes of the General Data Protection
        Regulation of the European Union (EU), the Personal Data Protection Law
        2021 of the United Arab Emirates (UAE) and data protection laws of other
        relevant jurisdictions where Users are located (collectively, the “
        <b>Data Protection Laws</b>”), the Company is a data controller (i.e.,
        the company who is responsible for, and controls the processing of, your
        Personal Data, as defined herein).
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY, THEN PLEASE DO
        NOT DOWNLOAD OR USE OR ACCESS OUR APP.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        From time to time, we may revise or amend this Privacy Policy to reflect
        changes in law, our Personal Data collection and use practices, the
        features of the Services, or advances in technology. This Privacy Policy
        does not cover the practices of entities we do not own or control, or
        people we do not manage.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        By accessing and/or using our Services, you shall be deemed to consent
        to all terms and conditions in this Privacy Policy.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        If you have any questions or requests regarding this Privacy Policy,
        please contact our Data Protection Officer at the contact details
        provided below (at the end of this Privacy Policy).
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>What Personal Data we collect</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        To the fullest extent permitted under applicable Data Protection Laws,
        we may collect<b> </b>the following data and information (collectively,
        “<b>Personal Data</b>”):
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Full name, date of birth, gender, country of residence, username,
            passwords, tax numbers, and data included in government-issued
            identification documents;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Financial information, sources of wealth or funds or income, level
            of activity anticipated, distributed ledger network wallet
            addresses;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Personal Documents such as government-issued IDs, passport, bank
            statements, utility bills, internet bills, income, account balances,
            financial transaction history, credit history, tax information, and
            credit scores, and other forms of identification, address
            verification and source of funds and wealth verification;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Corporate information such as legal entity name, doing business as
            name, legal domicile, fiscal domicile, object;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Corporate documents such as certificate of registration,
            constitution, memorandum &amp; articles of association, bylaws,
            statues, incumbency certificate, register of directors, register of
            shareholders, register of authorized signatories, registry excerpts,
            financial statements;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Information about how you access and use our Services, such as your
            actions on the Services, including your interactions with others on
            the Services, photos or media you upload to the Services, your
            username, you provide. We may access your device camera roll and
            camera with your permission. If you need to update your permissions,
            you can do so in your device’s settings;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Contact lists on your mobile device or in your email accounts so
            that you can identify your contacts on the Services and direct the
            invitation of your contacts. We only access your contact list
            information with your explicit permission;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Communications between the Company and you including emails, social
            media messages, job applications, customer support inquiries;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Current and historical information concerning your geographic
            location, GPS location, transaction location, and IP addresses that
            you use when you use and/or access our Services. We may use
            third-party services such as the Google Maps API to gather
            information about your location. Google uses various technologies to
            determine your location, including IP address, GPS, and other
            sensors that may, for example, provide Google with information on
            nearby devices, Wi-Fi access points, and cell towers (see Google’s
          </span>
          <span lang="ja" style={{ color: "black" }}>
            <a href="https://policies.google.com/privacy">
              <span style={{ color: "black", textDecoration: "none" }}> </span>
            </a>
            <a href="https://policies.google.com/privacy">
              <span lang="EN-US" style={{ color: "#1155CC" }}>
                Privacy Policy
              </span>
            </a>
          </span>
          <span style={{ color: "black" }}> to learn more);</span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span style={{ color: "black" }}>
            Information about your device and its software, such as your IP
            address, browser type, Internet service provider, platform type,
            device type, operating system, date and time stamp, a unique ID
            (that allows us to uniquely identify your browser, mobile device, or
            your account), and other similar information.
          </span>
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          We may monitor, record, and store your personal information in order
          to protect your safety or the safety of other Users, comply with
          relevant laws, to assist with regulatory or law enforcement efforts,
          to protect and defend our rights and property, or for other reasons
          relating to our provision of the Services. By using the Services, you
          consent to the recording, storage, and disclosure of such information
          you send or receive for these purposes.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Services are not available to anyone under the age of eighteen
          (18) (“<b>Minors</b>”). The Company will never knowingly and
          intentionally collect Personal Data from Minors. If you are a parent
          or guardian of a Minor and you believe or are aware that a Minor has
          provided us with Personal Data, please contact us immediately. If we
          become aware that we have collected Personal Data from Minors with or
          without verification of parental consent, we will take measures to
          delete that information from our systems.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        We need your Personal Data to provide the Services to you and/or perform
        our contractual obligations towards you. You can always refuse to
        provide your personal data. However, without providing Personal Data, we
        may not be able to provide you with the Services you are intending to
        receive.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <b>How we use your Personal Data</b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        To the fullest extent permitted under applicable under Data Protection
        Laws, we use your information to:
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Provide any information or Services that you have requested or
          ordered;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Compare information for accuracy and to verify it with third parties;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Provide, maintain, protect and improve our Services;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Manage, monitor, and administer your use of the Services and provide
          an enhanced, personal, user experience;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Manage our relationship with you (for example, customer services and
          technical support activities);
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Undertake internal testing of the Services or systems to test and
          improve their security and performance (in these circumstances, we
          would de-identify any information used for such testing purposes);
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Provide you with any information that we are required to send you to
          comply with our regulatory or legal obligations;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Detect, prevent, investigate or remediate, crime, illegal or to
          otherwise protect our legal rights (including liaison with regulators
          and law enforcement agencies for these purposes);
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Contact you to see if you would like to take part in our customer
          research (for example, feedback on your use of our Services);
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Monitor, carry out statistical analysis and benchmarking (provided
          that in such circumstances it is on an aggregated basis which will not
          be linked back to you or any living individual);
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Deliver advertising, marketing (including but not limited to
          in-product messaging) or information which may be useful to you;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Deliver joint content and services with third parties with whom you
          have a separate relationship (for example, social media providers);
          and
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          In addition to the legal and commercial uses listed above, we may be
          required to provide any and all of your Personal Data to governmental
          authorities as necessary to comply with the law. To the extent
          required by Data Protection Laws, or where we have a legitimate and
          lawful purpose for doing so, we may retain information about you.
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        The Services may contain technology that enables us to:
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Check specific information from your device or systems directly
          relevant to your use of the Services, applications or Services against
          our records to make sure the Services are being used in accordance
          with our end-user agreements and to troubleshoot any problems;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Obtain information relating to any technical errors or other issues
          with our Services;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Collect information about how you use the features of our Services;
          and
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Gather statistical information about the operating system and
          environment from which you access our Services.
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          If you become a follower of the Company in social networks, the
          processing of Personal Data will be governed by the policies of the
          Social Network, as well as by their terms of use, privacy policies and
          regulations that belong to the social network that corresponds in each
          case and that you have previously accepted.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Company will treat your Personal Data in order to correctly manage
          your presence in the social network, inform him of the Company
          activities, Services, as well as for any other purpose that the rules
          of the social networks allow.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          In no case will the Company use the profiles of followers in social
          networks to send advertising individually.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <b>Legal basis for processing Personal Data</b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        We collect your Personal Data for the following purposes:
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Based on your explicit consent, for marketing purposes, which can be
          revoked at any time;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Where necessary to perform any contract we enter into, or have entered
          into, with you to provide Services or provide access to our Services;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Where necessary for our legitimate business interests (or those of a
          third party) when your interests and fundamental rights do not
          override those interests;{" "}
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Where necessary to protect the vital interests of any
          individual,&nbsp; when your interests and fundamental rights do not
          override those interests;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Where necessary for public interest or in the exercise of official
          authority;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Where we need to comply with a legal or regulatory obligation either
          in the EU, the UAE or elsewhere.
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <b>
          <span lang="ja">Your legal rights</span>
        </b>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{
            marginTop: "12.0pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          The right to access your own Personal Data.
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to request information about how your Personal Data has been
          used and disclosed.
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to have your Personal Data rectified if it is inaccurate or
          incomplete.
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to request deletion or removal of your Personal Data where
          there is no good reason for processing to continue.{" "}
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to restrict processing of your Personal Data where there is
          no good reason for processing to continue.
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to data portability to enable moving, copying or
          transferring of Personal Data from one platform to another.
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The right to object to the processing of your Personal Data in certain
          circumstances.
        </li>
        <li
          className="MsoNormal"
          style={{
            marginBottom: "12.0pt",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Rights relating to profiling and automated decision making resulting
          from the processing of your Personal Data.
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The exercise of these rights is personal and therefore must be
          exercised directly by the interested party, requesting it directly to
          the Company, which means that any User, subscriber or collaborator who
          has provided their data at any time can contact the Company and
          request information about the data that it has stored and how it has
          been obtained, request the rectification of the same, request the
          portability of your Personal Data, oppose the processing, limit its
          use or request the cancellation of that data in Company’s files.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <span style={{ color: "black" }}>
          To exercise the rights of access, rectification, cancellation,
          portability and opposition, you must send an email to the Data
          Protection Officer together with a valid proof of identity such as a
          Government-issued ID document.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        You may opt-out of any future contacts from us at any time. You can do
        the following at any time by contacting us via the contact form given on
        our website. In certain circumstances, you can also exercise the rights
        available to you under Data Protection Laws
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Right to withdraw consent at any time – in circumstances where we ask
        for your consent to process your Personal Data, any consent is provided
        freely and if you decide to withdraw your consent, this will not affect
        the lawfulness of any processing carried out before you withdraw your
        consent, nor will it affect processing of your Personal Data conducted
        in reliance on lawful processing grounds other than consent. If you
        withdraw your consent, we may not be able to provide Services to you
        and/or performing any contracts we may have with you, and this may also
        result in the termination of any contracts you have with us and your
        being in breach of your contractual obligations or undertakings, and our
        legal rights and remedies in such event are expressly reserved.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        You have the right to complain to the relevant competent authorities
        about our collection, use and disclosure of your personal information.
        For instance, the Personal Data Protection Commission of the United Arab
        Emirates, or the relevant competent authorities of the relevant EU
        Member State.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        If you wish to exercise any of the rights set out above, please contact
        our Data Protection Officer at the contact details set out below. When
        applicable, and subject to your country of residence or domicile, you
        may be charged with a reasonable fee for our processing of access
        requests, but not for processing a correction Please note that in some
        cases we may reject requests for certain reasons (for example, if the
        request is unlawful or if it may infringe on the rights of others or if
        the request is clearly unfounded, repetitive or excessive).
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        We try to respond to all legitimate requests within one month. However,
        it could take us longer than a month if your request is particularly
        complex or you have made a number of requests. In this case, we will
        notify you and keep you updated.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        For the avoidance of doubt, when we collect Personal Data for marketing
        purposes only based on your explicit consent, you may unsubscribe from
        receiving marketing materials at any time by clicking on the
        “unsubscribe” link in the email you receive or updating your preferences
        to indicate through which modes of communication and what kind of
        marketing communications you wish to receive.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>
          <span style={{ color: "black" }}>
            Persons who may access your Personal Data
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Company’s authorized personnel, including but not limited to our
          Data Protection Officer, shall have access to your Personal Data on a
          need-to-know basis. Our authorized personnel are bound to
          confidentiality and non-disclosure agreements, and subject to strict
          company policies related to the access and use of the data.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          We may engage from time to time to third-party service providers,
          and/or contractors to assist the Company to provide the Services .
          Where applicable, we have entered into a data processing agreement
          compliant with Data Protection Laws with these third-parties to ensure
          data security and protection of your Personal Data against Data
          Beaches. Such Data Processors will only process your Personal Data to
          the extent required for the provision of Services for which they are
          engaged.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>
          <span style={{ color: "black" }}>Disclosure of Personal Data</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          <br />
          We do not share, rent, or sell the personal data that you provide us
          with other organizations without your express consent, except as
          described in this Privacy Policy.{" "}
        </span>
      </p>
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal">
        Subject to applicable laws, we may disclose personal data to third
        parties without your consent under the following circumstances:
        <br />
        <br />
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Business Transfers. We may share personal data when we do a business
        deal, or negotiate a business deal, involving the sale or transfer of
        all or a part of our business or assets. These deals can include any
        merger, financing, acquisition, or bankruptcy transaction or proceeding.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Compliance with Laws and Law Enforcement; Protection and Safety. We may
        share personal data for legal, protection, and safety purposes or to
        comply with laws.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Auditors, Professional Advisors and Service Providers. We may share
        Personal Data with those who need it to do work for us. These recipients
        may include third party companies and individuals to administer and
        provide the Service on our behalf (such as customer support, hosting,
        email delivery and database management services), as well as lawyers,
        bankers, auditors, and insurers.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Group. We may share personal data with our related corporations, parent
        company, subsidiaries and affiliates of the Company.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Marketing Activities. In case you have consented to receiving
        information/newsletters from us, we may transfer your personal data to
        our business partners in order for us or our business partners to inform
        you about upcoming opportunities and promote our respective products and
        services.
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Other. You may permit us to share your personal data with other
        companies or entities of your choosing. Those uses will be subject to
        the privacy policies of the recipient entity or entities.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Transfer of your Personal Data to Another Country</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        The Company may share your Personal Data with recipients based outside
        of your country of residence or domicile, if necessary for the purposes
        stated in this Privacy Policy. When we do send Personal Data abroad, we
        have in place adequate safeguards in compliance with the requirements
        under Data Protection Laws. This includes obtaining assurances from the
        recipient and ensuring that the recipient is bound by legally
        enforceable obligations to provide the transferred personal data a
        comparable standard of protection as that under Data Protection Laws.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>
          <span style={{ color: "black" }}>Personal Data Retention</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          We will retain your Personal Data for as long as it is necessary for
          the purposes of performing a contract, comply with our legal and
          regulatory obligations, and protect our legitimate interests. We
          reserve the right to retain your Personal Data for the aforementioned
          purposes, troubleshoot problems, assist with any investigation,
          enforce our Terms of Use or any other contract that we entered into
          with you, and other actions permitted by law; and for the purposes of
          preventing fraud, legal proceedings, complaints and disputes for as
          long as this is necessary to protect our legitimate interests, if we
          believe there is a prospect of litigation/dispute in respect to our
          relationship with you.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        To determine the appropriate retention period for your Personal Data, we
        consider the type of product or service we are providing for you, the
        purposes for which we process your Personal Data, the applicable legal
        requirements, and other legitimate interests.
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_qaekllwmyo9t" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Personal Data Security
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <span style={{ color: "black" }}>
          To protect your Personal Data, the Company takes all reasonable
          precautions and follows the best practices of the industry to prevent
          the loss, misuse, improper access, disclosure, alteration or
          destruction of the same.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        <span style={{ color: "black" }}>
          In addition to the purposes described in this section, we may also use
          information we gather to deliver targeted and interest-based
          advertising, marketing (including in-product messaging) or information
          to you which may be useful, based on your use of the Services or any
          other information we have about you (depending on the Services, you
          may able to configure these features to suit your preferences).
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be entirely secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. The transmission of
        personal information to and from our Website is at your own risk. You
        should only access the Website within a secure environment. We do not
        assume responsibility for the information you submit to or receive from
        us or for any unauthorized access or use of that information, and we
        cannot and do not guarantee the security of any information transmitted
        by you to us or vice versa. You agree not to hold us responsible for any
        loss or damage incurred as a result of any unauthorized access,
        modification, interception, destruction or use of the information you
        submit to or receive from us through the Internet. In the event of a
        data breach, we will notify you as soon as practicable as required by
        applicable law.
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_9atg0w53rah4" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Content from other Services
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Services may include embedded content (for example, videos,
          images, articles, etc.). The embedded content of other internet
          websites (“
          <b>Third-Party Sites</b>”) behaves in exactly the same way as if you
          had visited other internet websites.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          These Third-Party Sites may collect data about you, use cookies, embed
          an additional third-party tracking code, and monitor your interaction
          using this code.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        Please be aware that we are not responsible for the content or privacy
        practices of the Third-Party Sites. You acknowledge that we do not
        control how these Third-Party Sites collect, use and share your personal
        information and you further acknowledge that these Third-Party Sites
        fall outside the scope of this Privacy Policy and are governed by their
        own respective privacy policies. We are not responsible for the activity
        or content of any Third-Party Sites. We encourage our Users to be aware
        when they leave our site and to read the privacy statements of any other
        site that collects personally identifiable information.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Cookies</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Our Services use cookies. By accessing our Services we will inform
          you, through a pop-up banner, of our use of cookies.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span lang="ja" style={{ color: "black" }}>
            About cookies
          </span>
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Cookies are files, often with unique identifiers, that web servers
          send to Internet browsers and can then be sent back to the server each
          time the browser requests a page from the server.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Web servers use cookies to identify and track users while browsing the
          different pages of Services, as well as to identify users returning to
          Services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Cookies can be “persistent” cookies or “session cookies”. A persistent
          cookie consists of a text file sent by a web server to an Internet
          browser, which is stored by the browser and remains valid until the
          defined expiration date (unless the user deletes it before the
          expiration date ). On the other hand, a session cookie expires at the
          end of the user's session, when the Internet browser is closed.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={2} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span lang="ja" style={{ color: "black" }}>
            Cookies from the Services
          </span>
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span lang="ja" style={{ color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          On our Services, including our Website, and mobile applications, we
          use session and persistent cookies.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          We will send you the following cookies:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: ".0001pt",
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          a.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>Session Cookies</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          b.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Google Analytics: This cookie allows us to identify unique users,
          unique sessions, regulate the rate of requests and store information
          about user sessions and campaigns.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Cookie 1 is a session cookie, while Cookie 2 is a persistent cookie.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={3} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span lang="ja" style={{ color: "black" }}>
            How we use cookies
          </span>
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span lang="ja" style={{ color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Cookies do not contain personally identifiable information, but we
          have the possibility of linking the Personal Data we store about you
          with the information obtained and stored from cookies.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          We use the information we obtain from the use of our cookies for the
          following purposes:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: ".0001pt",
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          a.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Recognize your computer when you access and/or use our Services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          b.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Improve the usability of the Services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          c.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          {" "}
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>Analyze the use of our Services.</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "1in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          d.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>Manage the Services.</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "1in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          e.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>Third party cookies</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          When you use the Services, you may also be sent third-party cookies.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          Our service providers can send you cookies. They use the information
          they obtain through their cookies for the following purposes:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: ".0001pt",
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          a.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Track your browser on different Services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          b.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Create a profile of your Internet browsing.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: "1in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          c.
        </span>
        <span
          style={{
            fontSize: "7.0pt",
            lineHeight: "115%",
            fontFamily: '"Times New Roman",serif',
            color: "black",
          }}
        >
          {" "}
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        <span style={{ color: "black" }}>
          Select specific ads that may be of interest.
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={4} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <span lang="ja" style={{ color: "black" }}>
            Deletion and blocking of cookies
          </span>
        </li>
      </ol>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span lang="ja" style={{ color: "black" }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          You can, at any time, restrict, block or delete cookies from the
          Services. To do this, you must modify the configuration of your
          browser regarding the use of cookies through the “Preferences”,
          “Options” or “Tools” menu (the name of the menus or the procedure to
          access the cookie options vary depending on the browser used).&nbsp;
          Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via these links:
        </span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: 17,
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://support.google.com/chrome/answer/95647">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://support.google.com/chrome/answer/95647
          </span>
        </a>
        <span style={{ color: "black" }}> (Chrome);</span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: "17px",
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          </span>
        </a>
        <span style={{ color: "black" }}> (Firefox);</span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: 17,
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://help.opera.com/en/latest/security-and-privacy/">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://help.opera.com/en/latest/security-and-privacy/
          </span>
        </a>
        <span style={{ color: "black" }}> (Opera);</span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: 17,
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          </span>
        </a>
        <span style={{ color: "black" }}> (Internet Explorer);</span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: 17,
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </span>
        </a>
        <span style={{ color: "black" }}> (Safari);</span>
      </p>
      <p
        style={{
          width: "calc(100vw - 100px)",
          wordWrap: "break-word",
          marginLeft: "0.5in",
        }}
      >
        <span
          style={{
            marginRight: 17,
            fontFamily: '"Times New Roman",serif',
          }}
        >
          ●
        </span>
        <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
          <span lang="EN-US" style={{ color: "black", wordWrap: "break-word" }}>
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          </span>
        </a>
        <span style={{ color: "black" }}> (Edge).</span>
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_1bu8yny25vnp" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Navigation
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          When accessing and/or using the Services, non-identifying data may be
          collected, which may include the IP address, geolocation, a record of
          how services and Services are used, browsing habits and other data
          that cannot be used to identify the User.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Services use the following third-party analysis services: Google
          Analytics
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          The Company uses the information obtained to obtain statistical data,
          analyze trends, administer the Services, study navigation patterns and
          to gather demographic information.
        </span>
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_hhydivcabpc2" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Accuracy and veracity of Personal Data
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          You agree that the information provided to the Company is correct,
          complete, accurate and current. You are solely responsible for the
          veracity and correctness of the data you submit when access and/or
          using the Services, exonerating the Company from any responsibility in
          this regard.
        </span>
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Acceptance and consent
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          You declare to have been informed of the conditions on protection of
          Personal Data, You accept and consent to the treatment of the same by
          the Company in the manner and for the purposes indicated in this
          Privacy Policy.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Contact Data Protection Officer</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          For any questions or requests regarding this Privacy Policy, or to
          exercise the rights of access, rectification, cancellation,
          portability and opposition, you must contact our Data Protection
          Officer at:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        Email: info@crosstech.llc
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Address:
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Unit No: RET-R5-089
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Detached Retail R5
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Plot No: JLT-PH2-RET-R5
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Jumeirah Lakes Towers
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Dubai
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        United Arab Emirates
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        When writing to our Data Protection Officer, please indicate:
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          your full name and contact information;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          a government-issued ID for identification purposes;
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          a description of your query or request; and
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          the nature of your query or request in the subject header (for
          example, Correction of Personal Data Request, or Personal Data Access
          Request).
        </li>
      </ol>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_wjadly4xcvvi" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Change of Operatorship
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <span style={{ color: "black" }}>
          In case of incorporation, acquisition, merger or any other causes that
          cause the change of Operatorship of the Services, you expressly
          consent that your registration data and information are transferred by
          the Company to the new Operator. When and if this occurs, the Company
          will comply, in any case, with the duty of information to you.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Amendment to this Privacy Policy</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <br />
        We continually improve our methods of communication and add new
        functionality and features to our platforms and services. As such, we
        keep our Privacy Policy under review and we may amend it. We reserve the
        right to make changes and updates to this Privacy Policy without giving
        you prior notice. We encourage you to check this Privacy Policy
        frequently and anytime you submit personal data via our platform. You
        are responsible for keeping track of the changes made to the Privacy
        Policy. Your continued use of the Services shall constitute your
        agreement to be bound by any such changes to this Privacy Policy. If we
        make any changes, the updated Privacy Policy will be posted on the
        Website with a revised effective date.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        <b>Notice to California and Virginia Residents</b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Our Services are currently designed for use only outside of the United
        States. You should not use our Services from the United States.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        However, in the case we collect Personal Data from California and
        Virginia residents, this section provides additional details about the
        Personal Data we might collect about California and Virginia residents
        and the rights afforded to them under the California Consumer Privacy
        Act (“
        <b>CCPA</b>”) and the Virginia Consumer Data Protection Act (“
        <b>VCDPA</b>
        ”).{" "}
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        Subject to certain limitations and exceptions, the CCPA and VCDPA
        provide California and Virginia residents, respectively, with certain
        rights. Depending on the state in which you are a resident, you may have
        the right to:
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Confirm whether the Company processes your Personal Data;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span lang="ja">Correct inaccurate Personal Data;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Request details (in a readily usable format) about the categories and
        specific elements of Personal Data we collect;
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span lang="ja">Delete your Personal Data;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Opt out of any sales, sharing, targeted advertising, or profiling for
        certain decisions (as these terms are defined in the CCPA and/or VCDPA);
        and
      </p>
      <p
        className="MsoNormal"
        style={{
          marginLeft: ".5in",
          textJustify: "inter-ideograph",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}> ●</span>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Not be discriminated against for exercising these rights.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        We do not sell information about you to third parties. In order to help
        the Company deliver advertising and marketing on other platforms, we do
        allow third parties to collect information through our Services. Please
        see the “Content from other Services” section above for more details.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        We collect the following categories of personal information: identifiers
        (such as name, email address and IP address), Internet or other
        electronic network activity information (such as engagement with
        promotional messages and ads), and inferences (such as gender, based on
        your first name). For more details about the information we collect
        (including the categories of sources of this information) as well as the
        purposes for processing, please see the “What Personal Data we Collect”
        and “How we use your Personal Data” sections above. We share this
        information with the categories of third parties described in the
        “Disclosures of Personal Data” section above.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        California and Virginia residents may make a request to exercise rights
        under the CCPA and VCDPA, respectively, that are not otherwise exempt
        under applicable law. You may also request assistance by sending an
        email to our Data Protection Officer. We may verify the request by
        asking you to provide information that matches information we have on
        file about you. You can also designate an authorized agent to exercise
        these rights on your behalf, but we will require proof that the person
        is authorized to act on your behalf and may also still ask you to verify
        your identity with us directly. If you are a Virginia resident and the
        Company is unable to satisfy your request to exercise a right available
        to you under the VCDPA, you are entitled to appeal our decision by
        emailing the Data Protection Officer.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
      <h3
        style={{
          marginTop: "14.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
          lineHeight: "123%",
          pageBreakAfter: "auto",
        }}
      >
        <a name="_dp7m5aimm70e" />
        <b>
          <span
            style={{ fontSize: "11.0pt", lineHeight: "123%", color: "black" }}
          >
            Services Operator
          </span>
        </b>
      </h3>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: "0in",
          textAlign: "justify",
          textJustify: "inter-ideograph",
        }}
      >
        The Services are operated by CROSSTECH DMCC, a company registered in the
        Dubai Multi-Commodities Centre with address at RET-R5-089, Detached
        Retail R5, Plot No: JLT-PH2-RET-R5, Jumeirah Lakes Towers, Dubai.
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
      >
        &nbsp;
      </p>
    </div>
  );
};

export default PrivacyPolicy;
