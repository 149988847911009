import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1784 57.5207L32.8354 60.7847V25.4189L52.1692 14.2639L46.5046 11L27.1784 22.1474V57.5207ZM17 63.102L36.3262 74.257L41.9908 70.9855L22.657 59.8305V24.7525L17 28.0165V63.102ZM46.5203 80.7927L40.8482 77.5212L46.5203 74.2572L52.1774 70.9933L59.7276 66.6388L71.5187 59.8383L72.9349 59.0204L75.3885 57.6043L75.4112 57.5891V64.1246L46.5203 80.7851V80.7927ZM75.4009 51.9315V40.6024L75.4085 40.5948L56.3928 29.046V29.0536L50.7358 32.3175L69.7363 43.8739L69.7439 43.8663V55.1955L75.4009 51.9315ZM86.7016 69.736V37.6644L81.037 41.1329V66.3808L81.0369 66.3809V66.3812L46.6254 86.4722L17 69.1832V75.711L46.6254 93.0001L86.7016 69.736ZM57.0166 23.4503L37.6753 34.6052V28.0773L57.0166 16.9148L80.2278 30.3114V30.3038L85.8772 33.5602H85.8696L85.8848 33.5677L80.2202 36.8393L57.0166 23.4503Z" fill="#97b02d"/>
      </svg>
    <p>
      Sample project provided by <a href="https://auth0.com">Auth0</a>
    </p>
  </footer>
);

export default Footer;
