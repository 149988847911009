import React, { useEffect, useState } from "react";
import Chat3Modal from "../components/Chat3Modal";
import { useSizes } from "../hooks/useSizes";

const Chat3 = () => {
  const url = new URL(window.location.href);
  const [count, setCount] = useState(0);
  const { height } = useSizes();
  const token = url.searchParams.get("access_token");

  useEffect(() => {
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <div>
      <Chat3Modal token={token} height={height} />
    </div>
  );
};

export default Chat3;
