import { useEffect, useState } from "react";

export const useSizes = () => {
  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resize = () => {
      setSizes({ height: window.innerHeight, width: window.innerWidth });
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return sizes;
};
