import { Button, Form, Input, notification } from "antd";
import React from "react";
import styles from "./policy.module.css";
import { Typography } from "antd";
import axios from "axios";

const { Title } = Typography;
const { TextArea } = Input;

const Support = () => {
  const onFinish = async (values) => {
    await axios
      .get("https://mobile-api.staging.xenea.app/notify/support", {
        params: values,
      })
      .then((res) => {
        notification.success({ message: "sent successfully" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={styles.support_main}>
      <div className={styles.center}>
        <img
          src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/omega-wallet.png"
          width="200"
          style={{ marginBottom: 15, marginTop: "10%" }}
          alt="Your logo goes here"
        />
      </div>

      <Title level={1} className={styles.center}>
        Xenea Support
      </Title>
      <Form
        className={styles.formSupport}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
      >
        <Form.Item
          name={"email"}
          label="Email"
          rules={[
            { required: true, message: "Please input your Email!" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"title"}
          label="Title"
          rules={[{ required: true, message: "Please input your Title!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Content"
          name={"Content"}
          rules={[{ required: true, message: "Please input your Content!" }]}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ background: "#b1ce12" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Support;
