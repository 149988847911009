import React from "react";
import styles from "./policy.module.css";
import { Typography } from "antd";
const { Title, Text } = Typography;
const TermsOfUse = () => {
  return (
    <div className={styles.main}>
      <img src={"/logo_xeneawallet.png"} alt="Your logo blue" width={100} />

      <h2 style={{ marginBottom: 0 }}>
        <b>Xenea Wallet - Terms and Service</b>
      </h2>
      <span
        style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif',
        }}
      >
        (updated 9/17/2024)
      </span>

      <h2 style={{ marginBottom: "4.0pt", pageBreakAfter: "auto" }}>
        <a name="_k42qb9tfqz1" />
        <b>
          <span
            style={{
              fontSize: "17.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            <br />
            1.&nbsp;&nbsp;&nbsp;&nbsp; General Provisions
          </span>
        </b>
      </h2>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_8kl1sayxzaio" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            1.1 (Purpose)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Thank you for utilizing the Services provided by Xenea Initiative DMCC
          (
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          FKA CROSSTECH DMCC,{" "}
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          hereinafter referred to as the "Company"). This Terms of Service
          Agreement (hereinafter referred to as the "Agreement") governs the
          access to and use of the Xenea Wallet app service (hereinafter
          referred to as the "Service") provided by Xenea Initiative DMCC
          (hereinafter referred to as the "Company"). We kindly request you to
          review this Agreement carefully and also read all related terms,
          including the Privacy Policy.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_6cpy4p4zf7li" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            1.2 (Definitions)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          The definitions of terms used within these Terms of Service are as
          follows, without regard to capitalization:
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. "Service" refers to all services provided by the Company through
          members' devices (including various mobile, PC, and wireless devices).
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. "Mobile App" refers to a virtual commercial place or service space
          established by the Company to provide services using informational and
          communication facilities such as computers or mobile information and
          communication devices, including but not limited to, applications,
          webpages, etc.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. "Member" refers to any and all users who access the Service and
          enter into an agreement with the Company according to this Agreement
          to utilize the Service, either in full or in part. Users are required
          to register as members to use this Service, and upon approval of the
          registration, they become members.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          4. "ID" refers to the user's email account, under the name of an
          approved user, requested by the user during the registration process
          for this Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          5. "Points" refer to the scores available on the Service that the
          Company can grant to members, and the Company may use these points to
          reward members in the future.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          6. "Affiliate Content" refers to any content, whether free or paid,
          provided to members by the Company through partnerships with external
          entities.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_nsoldzspmj5u" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            1.3 (Publication and Revision of Terms of Service)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. The Company will publish these Terms of Service on the mobile app
          and other platforms providing the Service, ensuring that members can
          easily access and review them.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Within the limits not violating relevant laws, such as the
          Information and Communication Network Act, Act on Regulation of Terms
          and Conditions, Act on Reporting and Using Specified Financial
          Transaction Information, the Company may revise these Terms of
          Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. In the event of a revision to these Terms of Service, the Company
          shall provide notice of the revised terms, along with the effective
          date and reasons for the revision, from 7 days prior to the effective
          date until the day before the effective date, following the method
          specified in Clause 1. However, if the changes are disadvantageous to
          users from a legal perspective, the Company shall notify users through
          the Service at least 30 days before the effective date.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          4. If a member does not agree to the revised Terms of Service, the
          member may terminate the contract within 14 days from the date of the
          notice. If, despite the notification, a member does not explicitly
          express their refusal within 14 days from the date of the notice, it
          will be deemed that the member has agreed to the revised Terms of
          Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          5. If a member indicates their disagreement with the revised Terms of
          Service within the aforementioned period, the Company cannot apply the
          revised Terms of Service. In such a case, the member has the option to
          terminate the service contract. The member will not be able to use the
          Service from the effective date of the revised Terms of Service, and
          the Company may terminate the service contract with the member in this
          situation.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_h8m0orcszzfv" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            1.4 (Interpretation of Terms of Service)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. In order to provide the Service smoothly and comply with relevant
          laws, the Company may request members to follow additional procedures,
          such as agreeing to supplementary terms or consenting to the
          collection and use of personal information. If a member fails to
          complete such procedures, the member may not be able to use all or
          part of the Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Matters not stipulated in these Terms of Service shall be governed
          by the Act on Regulation of Terms and Conditions, the Information and
          Communication Network Promotion and Information Protection Act,
          relevant laws, customs, and operational policies posted in advance and
          notified to users.
        </span>
      </p>
      <h2 style={{ marginBottom: "4.0pt", pageBreakAfter: "auto" }}>
        <a name="_qk0eilmz7wjn" />
        <b>
          <span
            style={{
              fontSize: "17.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            <br />
            2.&nbsp;&nbsp;&nbsp;&nbsp; Service Use
          </span>
        </b>
      </h2>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_3dlm1sy40hhp" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.1 (Conclusion of the Use Contract)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. The contract of use between the Company and the Member (hereinafter
          referred to as the “Use Contract”) is established when the person who
          wishes to use the Service (hereinafter referred to as the “Applicant”)
          runs the mobile application on an electronic device capable of running
          a mobile app such as a computer or mobile phone, agrees to the
          contents of this "Terms of Service" and the "Consent to Personal
          Information Collection and Use," and applies for membership. Upon
          approval by the Company, the Agreement is concluded.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. If required by relevant laws, the Company may request verification
          of the Applicant's real identity and authentication through identity
          verification institutions or official identification documents. The
          user must successfully complete these verification and authentication
          procedures for membership registration. If the user refuses to do so,
          the Company may reject the membership application, and the Company
          shall not be liable for any disadvantages resulting from the user's
          failure to verify their identity.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. The Applicant must carefully review and agree to this Terms of
          Service and the Privacy Policy when applying for membership. By
          clicking the agreement button for each respective term, it is deemed
          that the Applicant has fully reviewed and expressed their consent to
          the terms.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          4. Membership registration is only allowed for individuals, and the
          principle adhered to is one account per person. If a person improperly
          uses multiple accounts, the Company reserves the right to arbitrarily
          delete accounts exceeding one for that individual, and any points,
          cards, etc., held by the deleted accounts will also be lost, for which
          the Company will not compensate the person.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          5. Users who are under the age of 14 or who require parental or legal
          guardian consent due to the age restrictions in their country are
          ineligible for membership. Even if membership approval has been
          granted, the user's membership contract shall be deemed null and void
          if they fall within the aforementioned category.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          6. The Applicant is obliged to provide accurate and truthful
          information during the membership application process, and the
          Applicant shall be responsible for any disadvantages or liabilities
          resulting from the provision of false information.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          7. The Company reserves the right to withhold the approval of a
          membership application for technical, operational, or other reasonable
          reasons as deemed appropriate by the Company.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_o0n2xusm48cz" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.2 (Contract Termination and Cancellation by Company)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          The Company may withhold acceptance or refuse the following membership
          applications, terminate the use contract retroactively, or restrict
          the use of the Service for a certain period if necessary. In each
          case, any points, cards, or other benefits associated with the
          respective ID shall be forfeited, and the Company shall not compensate
          the user for such losses.
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the Service cannot be provided due to the specified service
            environment or technical reasons.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the user provides false, missing, or erroneous information
            during the application process or fails to meet the Company's
            requirements.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the application name is not the applicant's own name, such as
            using a false name or another person's name.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When it is difficult to identify the applicant or verify their
            identity.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When an existing member submits duplicate membership applications.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When an individual under the age of 14 applies for use.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When applying for membership under a corporate name.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When a user re-applies for membership after having previously been
            subject to service restrictions or having lost membership status.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When a user is involved in or reasonably suspected of engaging in
            illegal activities.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the membership application violates any provisions in these
            Terms of Service that pertain to hindering the use of the Service or
            using illegal or unfair means that could impede the smooth operation
            of the Service.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the application is made with the intention of disturbing public
            order, decency, or social norms.
          </span>
        </li>
        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            When the use of the Service, based on the user's residence,
            constitutes a violation of foreign laws outside the jurisdiction of
            the United Arab Emirates or poses significant risks of constituting
            a violation of such laws.
          </span>
        </li>
      </ol>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_tvc48ocyu0ou" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.3 (Contract Termination by Members)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Members may apply for the termination of the Service use contract
          at any time through the account management menu within the Service,
          and the Company shall process the request promptly, within the next
          business day.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Upon termination of the membership contract by a member, unless the
          Company is required to retain member information in accordance with
          relevant laws and the privacy policy, all member information shall be
          destroyed.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. The Company shall not be held responsible for any damages incurred
          by a member due to the deletion of their ID and related information
          resulting from the termination application.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_g7veosie0ga2" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.4 (Modification of Member information)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Members may access and modify their personal information within the
          Service. However, the ID cannot be modified for the purpose of Service
          management.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. If there are any changes to the personal information provided
          during the membership application, the member must promptly update it.
          The Company shall not be liable for any damages or disadvantages
          incurred due to the member's failure to update their information in a
          timely manner.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_jft672z5g18d" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.5 (Account Management)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Accounts may only be used by the members themselves, and under no
          circumstances should any other person be permitted to use the member's
          account.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Members must directly manage their ID, password, and related email
          to ensure that no unauthorized use of their account occurs. Members
          shall bear full responsibility for all aspects of such management.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. If a member becomes aware that their account is being used
          improperly, they must immediately notify the Company and follow the
          Company's instructions.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          4. The Company shall not be held responsible for any disadvantages
          incurred by a member who fails to notify the Company or follow the
          Company's instructions as stated in the preceding clause.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          5. Members may access and modify their information through the service
          settings. However, certain account information necessary for service
          provision and management may not be modifiable. Members must update
          any changes to the information provided during service application
          directly within the Service or request the update of such information
          through email or customer support to maintain up-to-date information.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_40jv7chfwbw" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.6 (Service Configuration)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. The mobile app provides a service that allows users to accumulate
          points, cards, etc., through missions.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. The services provided by the Company consist of the following:
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Accumulation of rewards, such as points or cards: Rewards given to
            members for using the Service.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Consumption, use, and purchase of content: Content provided by the
            Company can be used or purchased.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Display of advertisements: Providing advertisements such as images,
            text, sound, and links.
          </span>
        </li>
        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Other services provided by the Company through development or
            alliances with other companies.
          </span>
        </li>
      </ol>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. The Company may provide information about the Service and products
          through various methods, such as wireless phones, email, text services
          (LMS/SMS), SNS, etc., for the convenience of users. Members may refuse
          to receive such information at any time. If a member does not wish to
          receive notifications, they may deactivate the notification settings.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_els7qjm9mexp" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.7 (Change of Service)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          The Company may add, modify, or change the whole or part of the
          Service at any time for the smooth operation or improvement of the
          Service. Additionally, the Company may temporarily or permanently
          suspend the provision of the Service or add new restrictions. If the
          entire or part of the free service is modified, changed, or
          terminated, the Company shall not provide separate compensation unless
          otherwise specified by relevant laws.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_7nk2e4iwcxcn" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.8 (Suspension and Restriction of Service)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. The Company may limit or suspend the entire or part of the Service
          in the following cases:
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Occurrence of equipment maintenance, replacement, or failure,
            communication disruption, or other reasons related to the Company or
            affiliated companies.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Necessary for service upgrade, system maintenance, and management.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Disruptions to normal service usage due to power outage, equipment
            failure, or excessive usage.
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Reasonable business, operational, or technical necessity as
            determined by the Company.
          </span>
        </li>
        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Other inevitable reasons, such as natural disasters or national
            emergencies.
          </span>
        </li>
      </ol>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. The Company shall make reasonable efforts to provide advance notice
          if the service suspension is predictable, and if not, it shall notify
          users afterward.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_9qj8u4s750ci" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            2.9 (Collection and Use of User's Personal Information and Usage
            Records)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          All information provided to the Company by users is subject to the
          Company's Privacy Policy regarding the collection and use of personal
          information. By using the Service, users agree to the collection and
          use of their personal information in accordance with the Privacy
          Policy, as required by relevant laws (including but not limited to the
          Personal Information Protection Act and related regulations and the
          Act on Reporting and Use of Specific Financial Transaction
          Information) and not limited to the Privacy Policy.
        </span>
      </p>
      <h2 style={{ marginBottom: "4.0pt", pageBreakAfter: "auto" }}>
        <a name="_vtbvkash0ox6" />
        <b>
          <span
            style={{
              fontSize: "17.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            <br />
            3.&nbsp;&nbsp;&nbsp;&nbsp; Obligations of the Company and Members,
            etc.
          </span>
        </b>
      </h2>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_s40zcwmi8lm4" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            3.1 (Obligations of the Company)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. The Company shall make every effort to provide the Service
          continuously and stably while complying with relevant laws and these
          Terms.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. The Company shall have security systems in place to protect
          members' personal information and shall publicly disclose and comply
          with its Privacy Policy.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_63idgh4v3k2o" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            3.2 (Obligations of a Member)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Members shall not engage in the following actions related to the
          use of the Service:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "12.0pt",
          marginRight: "0in",
          marginBottom: ".0001pt",
          marginLeft: ".5in",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Actions that violate these Terms or relevant laws.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Actions that disrupt normal Service operation, such as providing false
          information during registration, using another person's information,
          or registering multiple IDs for one person.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Hacking or unauthorized intrusion into the Company's information and
          communication network.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Unauthorized access, storage, disclosure, or use of information held
          by the Company, using improper methods other than those provided by
          the Company.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Collecting personal information of other members without their
          consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Damaging Service information by unauthorized alteration or deletion.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginLeft: ".5in", textIndent: "-.25in" }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Infringing on the Company's intellectual property rights, third-party
          intellectual property rights, or copying, replicating, selling,
          reselling, or transferring all or part of the Service components for
          commercial purposes without the Company's consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "12.0pt",
          marginLeft: ".5in",
          textIndent: "-.25in",
        }}
      >
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          Engaging in other illegal activities or actions that hinder the
          Company's business to provide smooth Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Members shall comply with relevant laws, the provisions of these
          Terms, service instructions, and notices posted within the Service.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. Members must personally use the Service and should not transfer or
          lend their accounts or devices with access to their accounts to
          others.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_26jo7ac214gc" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            3.3 (Notice to Members)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. When notifying all users, the Company shall do so by posting the
          notice on the mobile app's public announcement section for a period of
          at least 7 days.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. In cases where a notice has a significant impact on members, the
          Company shall individually notify members through their registered
          email or mobile app alerts.
        </span>
      </p>
      <h2 style={{ marginBottom: "4.0pt", pageBreakAfter: "auto" }}>
        <a name="_sdud876172wr" />
        <b>
          <span
            style={{
              fontSize: "17.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            <br />
            4.&nbsp;&nbsp;&nbsp;&nbsp; Damages Compensation and Indemnification,
            etc.
          </span>
        </b>
      </h2>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_r51bye5a3oxw" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            4.1 (Damages Compensation and Indemnification)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. In the event that the Company or a user causes damages to the other
          party by violating these terms, the responsible party shall be liable
          to compensate for the damages. However, the Company shall not be
          liable to compensate for damages caused by reasons not attributable to
          the Company.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. The Company, its executives, directors, employees, and agents shall
          not be held liable for,
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            The use or inability to use the Service,
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Information available through the Service,
          </span>
        </li>
        <li className="MsoNormal">
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            Any indirect, special, punitive, incidental, or consequential
            damages arising from acts of Service users or third parties;
          </span>
        </li>
      </ol>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          unless the Company acted with intentional misconduct or gross
          negligence.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_vh1asll4qw7r" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            4.2 (Governing Laws and Judicial Jurisdiction)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Unless otherwise specified in writing, these terms shall be
          governed and interpreted in accordance with the laws of the United
          Arab Emirates, regardless of the user's nationality or address.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. In the event of a dispute arising between a user and the Company,
          the jurisdictional court shall be the in the United Arab Emirates
          where applicable.
        </span>
      </p>
      <h3 style={{ marginTop: "14.0pt", pageBreakAfter: "auto" }}>
        <a name="_pw5hr6gwgxao" />
        <b>
          <span
            style={{
              fontSize: "13.0pt",
              lineHeight: "115%",
              fontFamily: '"Times New Roman",serif',
              color: "black",
            }}
          >
            4.3 (Miscellaneous)
          </span>
        </b>
      </h3>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          1. Even if a certain provision of these terms is found to be
          unenforceable or impractical through a court ruling, the remaining
          provisions shall remain valid and enforceable.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          2. Users may not transfer the rights and obligations specified in
          these terms to a third party without the Company's prior written
          consent.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>
          3. These terms represent the complete and exclusive agreement between
          the Company and users regarding the Service and replace all prior
          agreements related to the Service. If a user does not explicitly
          express their refusal within the specified period after the revised
          terms take effect, it shall be deemed that the user has agreed to the
          revised terms.
        </span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      </p>
      <p className="MsoNormal">
        <span lang="ja" style={{ fontFamily: '"Times New Roman",serif' }}>
          Addendum
        </span>
      </p>
      <ol style={{ marginTop: "0in" }} start={1} type={1}>
        <li
          className="MsoNormal"
          style={{ marginTop: "12.0pt", marginBottom: "12.0pt" }}
        >
          <span style={{ fontFamily: '"Times New Roman",serif' }}>
            These Terms of Service shall be effective from September 17, 2024.
          </span>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfUse;
