import React from "react";
import styles from "./policy.module.css";
import { Typography } from "antd";
const { Title, Text } = Typography;
const PrivacyPolicy = () => {
  return (
    <div className={styles.main}>
      <div className="WordSection1">
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_dkpv5hvyk05r" />
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>の特徴</span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            {" "}
            は、Xeneaブロックチェーンを支える重要なインフラツールの1つです。このウォレットは、3フェーズにおけるリリースが予定されており、2024年8月現在は第1フェーズです。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              第1フェーズ
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            これは日々のボーナス獲得やミッションをこなしながら、Xeneaやブロックチェーンについて学ぶことができる、ワクワクするフェーズです！
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            ウォレットにおける活動に参加すると、
          </span>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            における報酬の”gem”を獲得することができます。獲得したgemの数量に応じて、将来的なエアドロップやマイニングへの参加権であるマイニングパスポートの権利が確定します。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              第2フェーズ
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            Xenea
            WalletにWeb3ウォレット機能が搭載され、軽微な参加要件を満たしたユーザーはPoD
            (Proof of
            Democracy、旧名VPoW)コンセンサスによるマイニングに参加し、生涯にわたる受動収入を得ることができるようになります。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            また、ユーザーはシンプルなソーシャルログインと我々の秘密鍵分散の特許技術により、シードフレーズと秘密鍵の保管リスクから決別し、安全に暗号資産を活用することができるようになります。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              第3フェーズ
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            に法定通貨のオンランプやオフランプ機能が付き、ますます暗号資産を身近に使うことができるようになります。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            上記は、2024年8月1日時点の計画になり、プロジェクトの状況に応じて変更される可能性があります。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_zcyfbsuhyqhv" />
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}> の入手</span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            は、AndroidとiOSに対応しています。それぞれのバージョンは、以下のアプリストアから入手することができます。
          </span>
        </p>

        <p
          className="MsoNormal"
          style={{
            marginTop: "10.0pt",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0.5in",
            textIndent: "-.25in",
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>Android：</span>
          <a href="https://play.google.com/store/apps/details?id=com.xenea.wallet">
            <span style={{ color: "#1155CC" }}>
              https://play.google.com/store/apps/details?id=com.xenea.wallet
            </span>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "10.0pt",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>iOS：</span>
          <a href="https://play.google.com/store/apps/details?id=com.xenea.wallet">
            <span style={{ color: "#1155CC" }}>
              https://apps.apple.com/us/app/xenea-wallet/id6636520027
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            偽のウォレットをダウンロードしないようにご注意ください。万が一、偽のウォレットをダウンロードして被害を被ったとしてもチームは一切の責任を追うことはできません。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            また、Xenea
            Walletを入手したあとは、招待コードを入手しましょう。招待コードは友達から聴いたり、ソーシャルウェブで入手することができます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_18vvbkf4wu0c" />
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            FAQ（第1フェーズ）
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q.Xenea Wallet</b>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              は無料で使えますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            もちろん無料です！このウォレットを使ってキャンペーンに参加するための費用は必要ありません。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q.Xenea Wallet</b>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              は何のソーシャルログインに対応していますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            はGoogle IDとApple IDのソーシャルログインに対応しています。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q. プロフィールの編集はできますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            ユーザープロフィールはユーザーネームとプロフィール写真のみ変更することができます。ユーザーネーム、プロフィール写真は公開されます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            また、メールアドレスはサインアップ時のメールアドレスが登録され、変更することができませんのでご注意ください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q. Gemとは何ですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
            A Gem signifies the quantification of your dedication and
            contribution efforts to Xenea. Depending on the amount of gems you
            own, Xenea will provide you with short-term and long-term
            incentives. We highly recommend you login to the app on a daily
            basis to earn as many gems as you can.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>&nbsp;</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.ウォレットにリンクしているZealyやGalxeのメールアドレスを変更することはできますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            できません。ZealyやGalxeのメールアドレスとリンクさせる際は、気をつけてアドレスを入力してください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q. Galxeのメールアドレスが正しいのに、
            </span>
            Xenea Wallet
          </b>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              との連携を完了できません。
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            アプリ上に表示される案内に従ってください。GalxeでXeneaのアカウントをフォローし、何かタスクを完了させてください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q. Xenea Wallet</b>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              に登録した後に、招待コードを入力してgemを獲得することはできますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>できません。</span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q. Xenea Wallet</b>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              のアカウントを複数作成しても大丈夫ですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xena Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            では複数のアカウント作成は許可していません。必ずご自身が将来にわたり継続利用するアカウントを使用してください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            参加者間の平等性を確保するために、エアドロップを獲得できる権利は1ユーザーにつき1つとなり、最終的にシビル検知が行われます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.マイニングとは何ですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            フェーズ1のマイニングでは、XeneaブロックチェーンのコンセンサスであるPoDを擬似的に体験することができます。これはリアルのマイニングではありませんが、1分あたりに一定数量のgemが排出され、マイニングの資格を持つユーザーにgemが均等に配布されます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.マイニングの資格を得るためにはどうすれば良いですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            ミッションや招待を通して、10,000 gems以上を獲得しましょう。10,000
            gemsを獲得すると、自動的にマイニングの資格が割り当てられます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.Daily Quizが更新される時間はいつですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            ユーザーがいるタイムゾーンの日付が変わると、Daily
            Quizが更新されます。例えば、あなたがNigeria
            (UTC+1)にいる場合、現地時間 0:00（UTC 23:00）にDaily
            Quizが更新されます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.Daily Claimの日付が変わるタイミングはいつですか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            ユーザーがいるタイムゾーンの日付が変わると、Daily
            Claimの日付が変わります。例えば、あなたがNigeria
            (UTC+1)にいる場合、現地時間 0:00（UTC
            23:00）が日付が変わるタイミングです。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.エアドロップの権利を得られない国はありますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            あります。詳細は、フェーズ1終了時の正式アナウンスをお待ちください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.エアドロップはいつ行われますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            Xeneaの公式アナウンスをお待ちください。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.バウチャーコードはどのように入手できますか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            Xeneaの公式ソーシャルウェブのアカウントや、我々のコラボレーション先のキャンペーンで配られます。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            Xeneaの公式X:
          </span>
          <a href="https://twitter.com/Xenea_io">
            <span style={{ color: "#1155CC" }}>
              https://twitter.com/Xenea_io
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.上記以外の質問をするにはどうすればよいでしょうか？
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            XeneaのDiscordでサポートチケットを発行してください。その前に、コミュニティに似たような事例があるか確認するようにしましょう。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Discord:{" "}
          <a href="https://discord.com/invite/Xenea">
            <span style={{ color: "#1155CC" }}>
              https://discord.com/invite/Xenea
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            <span style={{ fontFamily: '"Arial Unicode MS"' }}>
              Q.サポートの返答がない。
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>
            我々は、多くの人にできるだけ満足いただけるよう努力していますが、メンバーの負荷状況により全員に満足した回答ができない場合があります。一般的な質問の場合は、コミュニティで質問してみることをおすすめします。
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_n5c4qghgd7yp" />
          Xenea Wallet Features
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Xenea Wallet is one of the key infrastructure tools supporting the
          Xenea blockchain. The wallet is scheduled for release in three phases,
          with the first phase being in August 2024.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Phase 1</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Phase 1 is an exciting phase where you can learn about Xenea and the
          blockchain while earning daily bonuses and completing missions!
          <br />
          <br />
          As you participate in activities in the wallet app, you will earn
          "gem" rewards. The number of gems you earn will determine your right
          to a Mining Passport, which entitles you to participate in future
          airdrops and mining activities.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Phase 2</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Xenea Wallet features Web3 wallet functionality, allowing users
          who meet minor participation requirements to participate in our PoD
          (Proof of Democracy, formerly known as VPoW) consensus mining and earn
          lifetime passive income.
          <br />
          <br />
          Users will also be able to securely leverage their crypto assets with
          a social login and our patented private key securitization technology,
          eliminating the risk of storing seed phrases and private keys.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Phase 3</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Xenea Wallet will have on and off-ramp functionality for legal
          tender, making crypto assets increasingly accessible.
          <br />
          <br />
          The above plans are as of August 1, 2024 and are subject to change
          based on project status.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_v2hcg5myiy3p" />
          How to download the Xenea Wallet
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Xenea Wallet is available for download with Android and iOS. Each
          version can be obtained from the following app stores:
        </p>

        <p
          className="MsoNormal"
          style={{
            marginTop: "10.0pt",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0.5in",
            textIndent: "-.25in",
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>Android：</span>
          <a href="https://play.google.com/store/apps/details?id=com.xenea.wallet">
            <span style={{ color: "#1155CC" }}>
              https://play.google.com/store/apps/details?id=com.xenea.wallet
            </span>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "10.0pt",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: ".5in",
            textIndent: "-.25in",
          }}
        >
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Arial Unicode MS"' }}>iOS：</span>
          <a href="https://play.google.com/store/apps/details?id=com.xenea.wallet">
            <span style={{ color: "#1155CC" }}>
              https://apps.apple.com/us/app/xenea-wallet/id6636520027
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Please be cautious of any fake wallets. The team cannot be held
          responsible for any damage caused by downloading any fake wallets.
          <br />
          <br />
          Further, after you download the Xenea Wallet, we recommend you obtain
          an invitation code. Invitation codes can be sent to friends or
          obtained on the social web.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <h2 style={{ marginTop: "10.0pt" }}>
          <a name="_t0olpmfz77z9" />
          FAQ (Phase 1)
        </h2>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: Is the Xenea Wallet free to use?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Of course it’s free! There is no cost to use this wallet or to
          participate in campaigns.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: What social logins does the Xenea Wallet support?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Xenea Wallet supports Google ID and Apple ID social logins.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: Can I edit my Profile?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Only the username and profile picture can be changed in the user
          profile. Usernames and your profile photo will be public.
          <br />
          <br />
          Please note your email address will be registered as the email address
          you used when you signed up and cannot ever be changed.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: What is a Gem?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <span style={{ fontSize: "10.0pt", lineHeight: "115%" }}>
            A Gem signifies the quantification of your dedication and
            contribution efforts to Xenea. Depending on the amount of gems you
            own, Xenea will provide you with short-term and long-term
            incentives. We highly recommend you login to the app on a daily
            basis to earn as many gems as you can.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>&nbsp;</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            Q: Can I change my Zealy or Galxe email address linked to my wallet?
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Unfortunately no. Please be mindful of the email address you use when
          linking your Zealy or Galxe account email addresses.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            Q: I cannot complete the integration with Xenea Wallet even though
            my Galxe email address is correct.
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Follow the directions displayed on the app. Also, follow Xenea's
          account on Galxe and complete any tasks.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            Q: Can I earn gems by entering an invitation code after registering
            for Xenea Wallet?
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          No, you cannot.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: Can I create multiple Xenea Wallet accounts?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xena Wallet does not allow the creation of multiple accounts. Be sure
          to use the account that you will continue to use in the future. To
          ensure equality among participants, only one airdrop will be awarded
          per user, with final civil detection.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: What is Mining?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Wallet Phase 1 mining provides a simulated experience of PoD,
          the consensus of the Xenea blockchain. This is not real mining, but a
          fixed quantity of gems is emitted per minute, and the gems are
          distributed equally to users who qualify for mining.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: How do I qualify for mining?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Earn 10,000 gems or more through missions and invitations. 10,000 gems
          will automatically assign you mining credentials.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: When is the Daily Quiz updated?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The Daily Quiz is updated when the date changes for the time zone in
          which the user is located. For example, if you are in Nigeria (UTC+1),
          the Daily Quiz will be updated at 0:00 local time (23:00 UTC).
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: When does the date on the Daily Claim change?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          The date of the Daily Claim changes when the date changes in the time
          zone where the user is located. For example, if you are in Nigeria
          (UTC+1), the date changes at 0:00 local time (23:00 UTC).
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>
            Q: Are there any countries where airdrop rights are not available?
          </b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Yes, there are. Please wait for the official announcement at the end
          of Phase 1 for details.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: When is the airdrop?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Please wait for an official announcement by Xenea.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: How do I obtain a Voucher Code?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          They will be distributed through Xenea's official social web accounts
          and through campaigns of our collaborators.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Xenea Official X:{" "}
          <a href="https://twitter.com/Xenea_io">
            <span style={{ color: "#1155CC" }}>
              https://twitter.com/Xenea_io
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: I have a question not listed above?</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Open a support ticket on the Xenea Discord. Before doing so, be sure
          to check if there are similar cases previously raised by the
          community.
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          Discord:{" "}
          <a href="https://discord.com/invite/Xenea">
            <span style={{ color: "#1155CC" }}>
              https://discord.com/invite/Xenea
            </span>
          </a>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          <b>Q: Support has not responded.</b>
        </p>
        <p className="MsoNormal" style={{ marginTop: "10.0pt" }}>
          We try to satisfy as many inquiries as possible, but due to member
          load conditions we may not be able to answer to everyone's
          satisfaction. For general questions, we recommend you ask them in the
          community.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
