import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Input, notification, Typography } from "antd";
const { Title } = Typography;

const Confirm = () => {
  let urlhref = window.location.href;
  let url = new URL(urlhref);
  let email = url.searchParams.get("email") ?? "";
  let secretKey = url.searchParams.get("secretKey") ?? "";
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_MOBILE_API_URI}/user/send/initial/holder`, {
        params: values,
      })
      .then((res) => {
        if (res.data.code === 0) {
          notification.success({ message: "Sent successfully" });
          setLoading(false);
          window.location = "/";
        } else {
          if (res.data.msg_code === "e_VOUCHER_NOT_EXIST") {
            notification.error({
              message: "You have already submitted this form.",
            });
          } else if (res.data.msg_code === "e_ACCOUNT_EXIST") {
            notification.error({
              message: "This Email Address has already registered.",
            });
          } else {
            notification.error({ message: res.data.msg });
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.msg_code === "e_VOUCHER_NOT_EXIST") {
          notification.error({ message: "Account is exits." });
        } else if (err.msg_code === "e_ACCOUNT_EXIST") {
          notification.error({
            message: "This Email Address has already registered.",
          });
        } else {
          notification.error({ message: err.msg });
        }
        setLoading(false);
      });
  };
  return (
    <div
      style={{
        background: "#f7f7f7",
        margin: "0 auto",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: 50,
          maxWidth: 500,
          margin: "0 auto",
          marginTop: 20,
          borderRadius: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            alt=" logo"
            src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/omega-wallet.png"
            width="180px"
          />
          <Title level={3} style={{ marginBottom: 30 }}>
            {/* Registration Form */}
            REGISTRATION FORM
          </Title>
        </div>

        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ email: email, secretKey: secretKey }}
        >
          <Form.Item name={"secretKey"} hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={"email"}
            label="Email Address"
            rules={[
              { required: true, message: "Please input Email Address." },
              {
                type: "email",
                message: "Please input a valid email.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"userName"}
            label="Full Name"
            rules={[{ required: true, message: "Please input Full Name." }]}
          >
            <Input />
          </Form.Item>
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <Form.Item className="f-end">
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={{ fontSize: 14 }}>
          <b>Note:</b>
          <ul>
            <li>You can submit this form only once.</li>
            <li>
              If you wish to receive Gems Giveaway, please submit your
              application <b>by November 20, 2024.</b>
            </li>
          </ul>
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          © 2024 Xenea Wallet
        </div>
      </div>
    </div>
  );
};

export default Confirm;
